import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { endOfMonth, startOfMonth } from 'date-fns';

import { IProperty } from '../../features/commons/properties/models/property.model';
import { PERIOD_PRESET } from '../../features/commons/stats-revenue/constants/presets';
import {
  CollectedType,
  StatsChartState,
  StatsFiltersResponse,
  StatsFlat,
  StatsForm,
  StatsResponse,
  StatsSettings,
  StatsSplitBy,
  StatsTypeOption,
} from '../../models';
import { StatsComparePeriod } from '../../models/objects/stats-compare-period';
import { StatsFiltersOptions } from '../../models/objects/stats-filters-options';

export const featureAdapter: EntityAdapter<StatsFlat> =
  createEntityAdapter<StatsFlat>({
    selectId: (model) => model.index,
  });

export const DEFAULT_FILTERS_VALUE: Partial<StatsForm> = {
  dates: [startOfMonth(new Date()), endOfMonth(new Date())],
  partitioning: 'monthly',
  compare: 'production_type',
  compare_year: null,
  year_on_year_flag: null,
  pickup: null,
  periodSelected: 'current_year',
  type: 'date',
  filters: {
    reservation_channel: null,
    accommodation: null,
    daily_rateplan: null,
    reservation_from: null,
    booker_category: null,
    bill_type: '1',
  },
};
export interface State extends EntityState<StatsFlat> {
  collectedOptions: StatsTypeOption[];
  typeOptions: { value: StatsSplitBy; label: string }[];
  compareOptions: { value: StatsSplitBy; label: string }[];
  addonsCategories: {
    [categoryId: number]: string;
  };
  selectedColumns: string[];
  isLoading?: boolean;
  isLoadingTableauNumbers: boolean;
  error?: any;
  stats: Partial<StatsResponse>;
  filtersOptionsByProperties: StatsFiltersResponse;
  filtersOptions: StatsFiltersOptions;
  properties: IProperty[];
  propertiesSelectedIds: number[];
  comparePeriod: StatsComparePeriod;
  enableValueChangeFilters: boolean;
  filtersHeaderValues: Partial<StatsForm>;
  min_date: Date;
  settings?: StatsSettings[];
  chart: StatsChartState;
  tableauNumbers: {
    accommodationRowsInViewport: StatsFlat[];
    accommodationRowsLoaded: StatsFlat[];
    accommodationRowsToLoad: StatsFlat[];
  };
  columnsLoaded: CollectedType[];
  splitLoading: boolean;
  totalLoading: boolean;
  totalsRow: StatsFlat;
  interruptCalls: boolean;
  exportId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  collectedOptions: [
    {
      value: 'gross_amount',
      label: 'gross_amount',
      defaultColumn: true,
    },
    {
      value: 'taxes',
      label: 'vat',
      defaultColumn: true,
    },
    {
      value: 'net_amount',
      label: 'net_amount_iva',
      defaultColumn: true,
    },
    {
      value: 'commissions_amount',
      label: 'commissions',
      defaultColumn: true,
    },
    {
      value: 'gross_amount_without_commissions',
      label: 'gross_amount_without_commissions',
    },
    {
      value: 'net_amount_without_commissions',
      label: 'net_amount_without_commissions',
    },
    {
      value: 'share_percent',
      label: 'incidence_percentage',
      defaultColumn: true,
    },

    {
      value: 'quantity',
      label: 'quantity',
    },
    {
      value: 'absolute_guests',
      label: 'guests',
    },
    {
      label: 'attendances',
      value: 'presence_guests',
      defaultColumn: false,
    },
    {
      value: 'arrivals',
      label: 'arrivals',
      defaultColumn: true,
    },
    {
      label: 'in_home',
      value: 'in_stay_guests',
      defaultColumn: false,
    },
    {
      value: 'departures',
      label: 'departures',
      defaultColumn: true,
    },
    {
      value: 'sold_nights',
      label: 'sold_nights',
    },
    {
      value: 'average_guest_earning',
      label: 'guest_average_revenue',
    },
    {
      value: 'average_stay_length',
      label: 'average_stay_length',
      defaultColumn: true,
    },
    {
      value: 'penalty_nights',
      label: 'penalty_nights',
    },
  ],
  typeOptions: [
    {
      value: 'date',
      label: 'period',
    },
    {
      value: 'production_type',
      label: 'department',
    },
    {
      value: 'service',
      label: 'service',
    },
    {
      value: 'accommodation',
      label: 'accommodation',
    },
    {
      value: 'booker_category',
      label: 'customer_type',
    },
    {
      value: 'channel',
      label: 'channel',
    },
    {
      value: 'reservation_from',
      label: 'market',
    },
    {
      value: 'nationality',
      label: 'nationality',
    },
    {
      value: 'dealer_agency',
      label: 'agency',
    },
    {
      value: 'dealer_company',
      label: 'company',
    },
    {
      value: 'dealer_tour_operator',
      label: 'tour_operator',
    },
    {
      value: 'property',
      label: 'property',
    },
    {
      value: 'reservation_reason',
      label: 'booking_reason',
    },
  ],
  compareOptions: [
    {
      value: 'rateplan',
      label: 'rate',
    },
    {
      value: 'nationality',
      label: 'nationality',
    },
    {
      value: 'reservation_from',
      label: 'market',
    },
    {
      value: 'booker_category',
      label: 'customer_type',
    },
    {
      value: 'dealer_company',
      label: 'company',
    },
    {
      value: 'dealer_agency',
      label: 'agency',
    },
    {
      value: 'dealer_tour_operator',
      label: 'tour_operator',
    },
    {
      value: 'date',
      label: 'period',
    },
    {
      value: 'property',
      label: 'property',
    },
    {
      value: 'reservation_reason',
      label: 'booking_reason',
    },
  ],
  addonsCategories: null,
  selectedColumns: null,
  isLoading: false,
  isLoadingTableauNumbers: false,
  error: null,
  stats: {
    dictionary: {},
    values: [],
    totals: null,
  },
  filtersOptionsByProperties: null,
  filtersOptions: null,
  properties: null,
  min_date: null,
  propertiesSelectedIds: null,
  comparePeriod: {
    comparePeriodOptions: null,
    minPropertyActivationDate: null,
    periodPreset: PERIOD_PRESET,
    pickupPreset: PERIOD_PRESET,
    defaultPreset: PERIOD_PRESET,
  },
  chart: {
    chartTypeSelected: 'gross_amount',
  },
  enableValueChangeFilters: false,
  filtersHeaderValues: DEFAULT_FILTERS_VALUE,
  accommodationRowsLoaded: null,
  tableauNumbers: {
    accommodationRowsInViewport: [],
    accommodationRowsLoaded: [],
    accommodationRowsToLoad: [],
  },
  columnsLoaded: [],
  splitLoading: false,
  totalLoading: false,
  totalsRow: null,
  interruptCalls: false,
  exportId: null,
});
